import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import { SectionLayout } from 'components/Sections/SectionLayout';
import Hero from 'components/Tools/Hero';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import DownloadableSplashPosts from 'components/Page/Downloads/SplashPage/DownloadableSplashPosts';

const DownloadSplashTemplate = (props) => {
  const { contentType, industry, internalName, url: pathname } = props.pageContext;
  const { jobStage } = props.location?.state || {};
  const isDefaultHero = internalName === 'Downloadable Content | Default';
  const pageComponents = props.data.contentfulComponentCollection.components;
  const hero = pageComponents.find((component) => component.__typename === 'ContentfulDynamicHero');
  const richText = pageComponents.find(
    (component) => component.__typename === 'ContentfulRichText',
  );
  const seo = pageComponents.find((component) => component.__typename === 'ContentfulSeo');

  if (isDefaultHero) {
    const heroTitle = JSON.parse(hero.heading1.raw);
    heroTitle.content[0].content[0].value = `${industry || ''} ${contentType}`;
    hero.heading1.raw = JSON.stringify(heroTitle);

    const heroSubtitle = JSON.parse(hero.subhead.raw);
    heroSubtitle.content[0].content[0].value =
      contentType === 'Licensing'
        ? 'The procedures for obtaining a license for HVAC, electrical or plumbing work can vary widely depending on location. Our state-by-state look at the requirements helps demystify the process.'
        : 'Make running your business easier with our free templates for everything from service call checklists to invoices.';
    hero.subhead.raw = JSON.stringify(heroSubtitle);
  }

  const allPosts = props.data.posts?.nodes.filter((post) => !post.splashPage);

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: contentType,
        icon: null,
        url: industry ? `/${contentType.toLowerCase()}` : null,
        hideInMobile: !industry,
      },
      ...(industry
        ? [
            {
              title: industry,
              icon: null,
              url: null,
            },
          ]
        : []),
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  return (
    <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={seo} layout="toolbox" {...props}>
      <Hero data={hero} noOverlap />
      <DownloadableSplashPosts
        type={contentType}
        posts={allPosts}
        industry={industry}
        stage={jobStage}
        pathname={pathname}
      />
      <StyledSectionLayout>
        <ContentfulRichText richText={richText} blogContentStyling />
      </StyledSectionLayout>
    </ToolboxLayout>
  );
};

export default DownloadSplashTemplate;

export const DownloadSplashTemplateQuery = graphql`
  query DownloadSplashTemplateQuery($contentful_id: String!, $contentType: String!) {
    contentfulComponentCollection(contentful_id: { eq: $contentful_id }) {
      ...ComponentCollection
    }
    posts: allContentfulDownloadableContent(
      filter: { downloadableContentType: { eq: $contentType } }
    ) {
      nodes {
        ...DownloadableContent
      }
    }
  }
`;

const StyledSectionLayout = styled(SectionLayout)`
  .section-layout--inner-container {
    padding-top: 0;
  }
  .richtext-sidebar--container {
    float: right;
  }
`;

import React from 'react';
import { Col } from 'components/Core/Grid';
import { getSubheading } from 'utils/text';
import toolboxLogos from 'components/Page/Toolbox/data/toolboxLogos';
import Cards from 'components/Core/Cards';
import Text from 'components/Core/Text';

import { Container } from './styles';

const DownloadSplashPosts = ({ post }) => {
  const { downloadBlackLogo } = toolboxLogos();

  const placeholderTitle = (
    <Text type="h6" color="white" className="download-splash--placeholder-title">
      {post.title}
    </Text>
  );

  return (
    <Col xs={24} sm={12}>
      <Container>
        <Cards
          type="Image"
          bodyType="simple"
          maxImageHeight="216px"
          coverImage={post.featuredImage}
          coverOverlay={!post.featuredImage?.image?.file && placeholderTitle}
          category={post?.seo?.taxonomy?.industries?.join(', ') || null}
          title={post.title}
          description={getSubheading(post.excerpt?.excerpt, null, 130)}
          ctaLink={post.seo?.pageUrl}
          fade
        />
      </Container>
    </Col>
  );
};

export default DownloadSplashPosts;

import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  .signup-form--title {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-22);
  }
  .webinar-on-demand--placeholder-title {
    text-align: center;
    padding: 0 20px;

    @media (max-width: 575px) {
      font-size: var(--font-size-22);
    }
  }
`;

export { Container };

import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

const Container = styled(SectionLayout)`
  .download-posts-container--title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .download-posts-container--filter-btn {
      visibility: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .download-posts-container--filter-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      &.selected {
        color: var(--titan-blue-3);
      }
    }

    .download-posts-container--job-stage-desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      p {
        font-weight: 800;
        line-height: 24px;
        color: var(--darkest);
        font-size: var(--font-size-p-md);
        margin: 0;
      }

      .download-posts-container--job-stage-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px 0 0;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
    .download-posts-container--post-count-desktop {
      display: block;
      color: var(--darkest);
      font-size: var(--font-size-p);
    }
  }

  .download-posts-container--more-btn {
    margin: 60px auto;
    display: flex;
  }

  @media (max-width: 767px) {
    .section-layout--inner-container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .download-posts-container--title-container {
      justify-content: space-between;

      .download-posts-container--post-count-desktop {
        display: none;
      }

      .download-posts-container--filter-btn {
        visibility: visible;
      }

      .download-posts-container--post-count-mobile {
        margin-bottom: 15px;
      }
    }

    .download-posts-container--mobile-title-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
  }
`;

export { Container };

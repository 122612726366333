import React, { useState, useEffect } from 'react';
import { Col, Row } from 'components/Core/Grid';
import { navigate } from 'gatsby';
import Button from 'components/Core/Button';
import DownloadSplashPosts from '../Posts';
import DownloadableContentFilters from '../Filter';
import { jobStageData } from '../static/data';
import { Container } from './styles';

const DownloadableSplashPosts = ({ industry, type, stage, posts }) => {
  const filteredPostsByIndustry = () =>
    industry ? posts.filter((post) => post.seo?.taxonomy?.industries?.[0] === industry) : posts;

  const [filteredPosts, setFilteredPosts] = useState(filteredPostsByIndustry());
  const [numberOfPosts, setNumberOfPosts] = useState(4);
  const [jobStage, setJobStage] = useState(stage || 'all');

  useEffect(() => {
    let filteredPostsByJobStage = filteredPostsByIndustry();
    if (jobStage && jobStage !== 'all') {
      filteredPostsByJobStage = filteredPostsByJobStage.filter(
        (post) => post.jobStage === jobStageData[jobStage].label,
      );
    }
    setFilteredPosts(filteredPostsByJobStage);
  }, [jobStage]);

  const onShowMorePostsClick = () => {
    setNumberOfPosts(numberOfPosts + 4);
  };

  const handleFilterUpdate = (value) => {
    const path = `/${type.toLowerCase()}/${value === 'All-Industries' ? '' : value.toLowerCase()}`;
    const state = { jobStage };

    navigate(path, { state });
  };

  const handleJobStageUpdate = (newStage) => {
    setJobStage(newStage);
  };

  const getValidPostCount = () => {
    return numberOfPosts > filteredPosts?.length ? filteredPosts?.length : numberOfPosts;
  };

  return (
    <Container>
      <Row gutter={40} type="flex">
        <Col xs={24} sm={24} md={8}>
          <DownloadableContentFilters
            posts={posts}
            type={type}
            jobStage={jobStage}
            handleFilterUpdate={handleFilterUpdate}
            handleJobStageUpdate={(value) => handleJobStageUpdate(value)}
            isTypeSplash
            industry={industry}
            pageInfo={
              filteredPosts.length > 0
                ? `Showing 1-${getValidPostCount()} of ${filteredPosts.length}`
                : ''
            }
          />
        </Col>
        <Col xs={24} sm={24} md={16}>
          <div className="download-posts-container--title-container">
            {type === 'Templates' ? (
              <p className="download-posts-container--job-stage-desktop">
                {jobStage && jobStageData[jobStage]?.icon && (
                  <div className="download-posts-container--job-stage-icon">
                    <img src={jobStageData[jobStage]?.icon} alt={jobStageData[jobStage]?.label} />
                  </div>
                )}
                <p>{`${jobStageData[jobStage]?.label || 'All Stages of The Job'}`}</p>
              </p>
            ) : (
              <div />
            )}
            <span className="download-posts-container--post-count-desktop">
              {filteredPosts.length > 0
                ? `Showing 1-${getValidPostCount()} of ${filteredPosts.length}`
                : ''}
            </span>
          </div>
          <Row gutter={[40, 40]} type="flex">
            {filteredPosts.length ? (
              filteredPosts
                .slice(0, numberOfPosts)
                .map((post, index) => <DownloadSplashPosts type={type} post={post} key={index} />)
            ) : (
              <Col xs={24} sm={24}>
                <p className="text-[18px]">
                  No templates were found that match the search criteria.
                </p>
              </Col>
            )}
          </Row>
          {filteredPosts.length > numberOfPosts && (
            <Button
              className="download-posts-container--more-btn"
              type="tertiary"
              size="big"
              onClick={() => onShowMorePostsClick()}
            >
              See more
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DownloadableSplashPosts;
